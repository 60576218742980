<template>
  <component
    v-for="(section, index) in sectionArr"
    :key="index"
    :is="routeMap[section]"
  />
  <PayAside v-if="showPayAside"></PayAside>
</template>

<script setup lang="ts">
import 'swiper/css';
import 'swiper/scss/pagination';
import pageHook from '@/hooks/pageHook.ts';
import PayAside from '@/components/common/PayAside.vue';
const { sectionArr, routeMap, showPayAside } = pageHook();
</script>
