export default {
  "home": "ホーム",
  "news": "最新情報",
  "community": "コミュニティ",
  "character": "キャラクター紹介",
  "world": "世界観",
  "features": "ゲーム紹介",
  "topUp": "チャージ",
  "customerService": "お問い合わせ",
  "download": "今すぐダウンロード",
  "moreDownload": "ダウンロード情報",
  "screenshot": "スクリーンショット",
  "video": "動画",
  "poster": "ギャラリー",
  "downloadGame": "ダウンロード",
  "scan": "QRコードからダウンロード",
  "prev": "前へ",
  "next": "次へ",
  "moreNews": "もっと見る",
  "latest": "最新",
  "fwtk": "利用規約",
  "yszc": "プライバシーポリシー",
  "noContent": "内容がありません。",
  "goPay": "向かう",
  "payGuide": "詳細内容",
  "stayTurn": "お楽しみに",
  "monster1": "AGF D-01　デビルタイラント",
  "monster1_text": "統治用ビースト、エクリプスが生み出した近接型ビーストである。",
  "monster1_skill1": "レイジフィールド",
  "monster1_skill2": "マグネティックオーバーロード",
  "monster1_skill3": "ハイボルテージ",
  "monster1_skill4": "殺戮コア",
  "monster2": "BS y23c　ホワイトカイザー",
  "monster2_text": "推進用ビースト、エクリプスが生み出した対歩兵用ビースト。",
  "monster2_skill1": "コングパンチ",
  "monster2_skill2": "ブレイクストライク",
  "monster2_skill3": "フランキング戦術",
  "monster2_skill4": "サポーター",
  "monster3": "AGF B-05D　スパイクドラゴン",
  "monster3_text": "防御型要塞、エクリプスが生み出した近距離戦・突撃用ビースト。",
  "monster3_skill1": "鉄甲突進",
  "monster3_skill2": "アイアンウォール",
  "monster3_skill3": "迂回戦術",
  "monster3_skill4": "嗜血コア",
  "monster4": "AGF A-03　インパクトマンモス",
  "monster4_text": "超重量＆長距離型プラットフォーム。エクリプスが創り出した巨大な長距離爆撃用ビーストである。",
  "monster4_skill1": "ロケット弾幕",
  "monster4_skill2": "TNT爆弾",
  "monster4_skill3": "侵略戦術",
  "monster4_skill4": "破滅コア",
  "monster5": "AAF I-02　バーニングプテラノドン",
  "monster5_text": "偵察型ユニット、エクリプスが生み出した潜入用ビースト。",
  "monster5_skill1": "バーニングアタック",
  "monster5_skill2": "火の海",
  "monster5_skill3": "速攻戦術",
  "monster5_skill4": "殺戮コア",
  "newsMainTitle": "ゲーム情報",
  "roleMainTitle": "ビースト",
  "worldMainTitle": "AIに支配される恐怖から世界を救え",
  "specialMainTitle": "ゲーム紹介",
  "worldText1": "AIにより支配されし、荒廃した世界であなたは生き残れるのか",
  "worldText2": "かつて栄えた人類社会はAIによって作られた機械ロボット",
  "worldText3": "「ビースト」の手によって一瞬にして滅びた。",
  "worldText4": "そこから数百年間、人類は残りの資源を奪い合ってきた——その地に「あなた」が現れるまで……",
  "worldText5": "生存者を率いてビーストを狩猟、改造せよ！",
  "worldText6": "志を同じくする同士で同盟を結成し、人類最後のエデンの園を救え！",
  "back": "戻る",
}
