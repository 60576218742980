export default {
  "home": "HOME",
  "news": "NOTIZIE",
  "community": "COMUNITÀ",
  "character": "PERSONAGGI",
  "world": "IMPOSTAZIONI MONDO",
  "features": "FUNZIONI",
  "topUp": "RICARICA",
  "customerService": "SERVIZIO CLIENTI",
  "download": "SCARICA",
  "moreDownload": "Più Metodi di Download",
  "screenshot": "SCREENSHOT",
  "video": "CINEMATICO",
  "poster": "POSTER",
  "downloadGame": "SCARICA",
  "scan": "Scansiona codice QR per scaricare",
  "prev": "Precedente",
  "next": "Prossimo",
  "moreNews": "INFORMAZIONI",
  "latest": "PIÙ RECENTI",
  "fwtk": "TERMINI DEL SERVIZIO",
  "yszc": "POLITICA SULLA RISERVATEZZA",
  "noContent": "Ancora nessun contenuto",
  "goPay": "VAI ORA",
  "payGuide": "SCOPRI DI PIÙ",
  "stayTurn": "Rimaniamo in attesa di ulteriori aggiornamenti",
  "back": "Indietro",

}
