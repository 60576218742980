<template>
  <div class="pay-aside"
       v-if="payAsideState.show"
       :class="{ active: !!showPayAside }"
       :style="{'background-image': `url(${payAsideState['bg_'+ nowLang.slice(-2)] || payAsideState.default_bg})`}"
       @click="clickBtnUpload">
    <a href="javascript:;"
       class="wrap"
       :class="{active: showPayAside}"
       @click="handlePayAside(!showPayAside)"></a>
    <a :href="`${payAsideState['payLink_'+ nowLang.slice(-2)] || payAsideState.default_payLink}&language=${nowLang}`"
       target="_blank"
       data-reason="payAside"
       data-type="goPay"
       class="go-btn ib">
      {{ $t('goPay') }}</a>
    <a href="javascript:void(0);"
       data-reason="payAside"
       data-type="learnMore"
       @click="showIframePopup(`${payAsideState.guidePage}?language=${nowLang}`)"
       class="learn-btn ib"
    >{{ $t('payGuide') }}</a>
  </div>
</template>

<script lang="ts" setup>
import indexHook from "@/hooks/indexHook";
import { clickBtnUpload } from '@/utils/pointUtils';
import websiteConfig from "@/configs/websiteConfig";
import { nowLang } from "@/language";
import { showIframePopup } from "@/hooks/popupHook";

const { showPayAside, handlePayAside } = indexHook();
const payAsideState = websiteConfig.payAside;
setTimeout(() => {
  showPayAside.value = false;
}, 3000)
</script>
