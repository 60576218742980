export default {
  "home": "首页",
  "news": "最新资讯",
  "community": "社群信息",
  "character": "角色介绍",
  "world": "世界观",
  "features": "游戏特色",
  "topUp": "储值购点",
  "customerService": "客服中心",
  "download": "立即下载",
  "moreDownload": "更多下载方式",
  "screenshot": "截图",
  "video": "视频",
  "poster": "海报",
  "downloadGame": "下载游戏",
  "scan": "扫一扫下载游戏",
  "prev": "上一页",
  "next": "下一页",
  "moreNews": "更多资讯",
  "latest": "最新",
  "fwtk": "服务条款",
  "yszc": "隐私政策",
  "noContent": "暂无文章，敬请期待",
  "goPay": "立即前往",
  "payGuide": "了解详情",
  "stayTurn": "敬请期待",
  "back": "返回",
}
