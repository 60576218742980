<template>
  <div
    class="footer"
    :class="{ addHeight: isDetailPage }"
  >
    <div class="left logo"
         :class="'logo_' + BRAND"
    >
    </div>
    <div class="center" v-if="ageLimit.show">
      <span class="ageIcon ib" :class="'age_' + ageLimit.age"></span>
      <div class="text-wrap">
        <p class="limitText" v-for="(item, index) in ageLimit.text" :key="index">{{ item }}</p>
      </div>
    </div>
    <div class="right">
      <div class="term">
        <a
            v-for="(item, index) in termArr"
            :key="index"
            :href="item.link"
            target="_blank"
        >{{ $t(item.name) }}</a
        >
      </div>
      <p class="copyright">
        <cite>{{ copyright }}</cite>
      </p>
      <p v-if="visibleOn37games" class="copyright">
        <cite>BUILDING-BLOCKS NETWORK TECHNOLOGY CO.,LIMITED</cite>
      </p>
      <p v-if="visibleOn37games" class="copyright">
        <cite>G-CONG NETWORK TECHNOLOGY CO.,LIMITED</cite>
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import footerHook from '@/hooks/footerHook';
import { getCurrentHost } from '@/utils';
const { BRAND, isDetailPage, copyright, termArr, ageLimit } = footerHook();
const visibleOn37games = getCurrentHost() === '37games.com';
</script>
