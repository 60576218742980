export default {
  "home": "Beranda",
  "news": "Info Terbaru",
  "community": "Info Komunitas",
  "character": "Introduksi Karakter",
  "world": "Pandangan Dunia",
  "features": "Keunikan Game",
  "topUp": "Top Up",
  "customerService": "Pusat Bantuan",
  "download": "Unduh Sekarrang",
  "moreDownload": "Cara unduh lainnya",
  "screenshot": "Tangkapan Layar",
  "video": "Video",
  "poster": "Poster",
  "downloadGame": "Unduh Game",
  "scan": "Pindai Kode QR untuk unduh game",
  "prev": "Sebelumnya",
  "next": "Selanjutnya",
  "moreNews": "Info Lainnya",
  "latest": "Terbaru",
  "fwtk": "Persyaratan Layanan",
  "yszc": "Kebijakan Privasi",
  "noContent": "",
  "goPay": "Menuju",
  "payGuide": "Cek Detail",
  "stayTurn": "Silakan nantikan informasi selanjutnya.",
  "back": "kembali",

}
