// createWebHistory:history路由
//  createWebHashHistory：hash路由
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router';
import websiteConfig from '@/configs/websiteConfig'
import Home from '@/views/Index.vue';
import Detail from '@/views/Detail.vue';
const DEFAULT_TITLE = websiteConfig.basic.title;
const DEFAULT_DESCRIPTION = websiteConfig.basic.description;
const DEFAULT_KEYWORD = websiteConfig.basic.keywords;
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/list',
    name: 'List',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/List.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      keepAlive: true,
    },
    component: Detail,
  },
  {
    path: '/:catchAll(.*)', // 不识别的path
    redirect: '/',
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// 回顶部
router.afterEach((to, from, next) => {
  // @ts-ignore
  // document.title = to.meta.title || DEFAULT_TITLE;
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

});
export default router;
