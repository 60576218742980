<template>
  <div
    class="download limit-px ib"
    @mouseover="downloadSlideActive = true"
    @mouseleave="downloadSlideActive = false"
    @click="mobileHeadDownloadClick"
  >
    <span class="text">{{$t('download')}}</span>
    <div
      class="slide"
      :class="{ active: downloadSlideActive }"
      @click="clickBtnUpload"
    >
      <a
        :href="downloadLink.appstore"
        target="_blank"
        class="apple ib common-download-apple"
        :class="nowLang"
        data-reason="head"
        data-type="appstore"
      ></a>
      <a
        :href="downloadLink.googlePlay"
        target="_blank"
        class="google ib common-download-google"
        :class="nowLang"
        data-reason="head"
        data-type="google"
      ></a>
      <a
        v-if="downloadLink.apk"
        :href="downloadLink.apk"
        class="apk ib common-download-apk"
        :class="nowLang"
        data-reason="head"
        data-type="apk"
      ></a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clickBtnUpload } from '@/utils/pointUtils';
import { nowLang } from "@/language";
import menuHook from '@/hooks/menuHook';
const { downloadSlideActive, downloadLink, mobileHeadDownloadClick } = menuHook();
</script>
