<template>
  <div class="fixed-header">
    <a
      class="left hamburger ib"
      href="javascript:void(0)"
      @click="togglePopup('mobileMenu')"
    ></a>
    <a class="center logo" href="#/" :style="{'background-image': `url(${websiteConfig.menu.logo})`}"></a>
    <div class="right">
      <MenuList></MenuList>
      <SocialSlide></SocialSlide>
      <LangSelect v-if="showLang"></LangSelect>
      <DownloadSlide></DownloadSlide>
    </div>
  </div>
</template>

<script setup lang="ts">
import popupHook from '@/hooks/popupHook';
import langHook from '@/hooks/langHook';
import websiteConfig from "@/configs/websiteConfig";
import LangSelect from '@/components/header/LangSelect.vue';
import SocialSlide from '@/components/header/SocialSlide.vue';
import DownloadSlide from '@/components/header/DownloadSlide.vue';
import MenuList from '@/components/header/MenuList.vue';
const { togglePopup } = popupHook();
const { showLang } = langHook();
</script>
