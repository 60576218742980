<template>
  <div class="pc-menu" @click="changeNav">
    <a
      class="menu-item menu-item-px"
      :data-index="index + 1"
      href="javascript:;"
      v-for="(menu, index) in menuListMap"
      :key="index"
      >{{ $t(menu.name) }}</a
    >
  </div>
</template>

<script lang="ts" setup>
import menuHook from '@/hooks/menuHook';
const { changeNav, menuListMap } = menuHook();
</script>
