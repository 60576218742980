const websiteConfig = {
    basic: { // 官网基础信息
        gameId: 295,
        packageName: '', // 配置微端端包名
        websiteId: 'mcdofficial',
        title: 'Mecha Domination: Rampage',
        description: '',
        keywords: '',
        review: true, // 是否审核模式，如果为true时，只有满足链接store=1时才会展示第三方储值相关
    },
    // 官网模块内容及顺序
    section: [
        'index', // 首屏
        'video', // 视频
        'news', // 新闻资讯
        'thirdParty', // 社群
        'role', // 角色
        'world', // 世界观
        'special' // 特色
    ],
    menu: {
        list: [
            // 导航栏配置：
            // name:显示名称
            // type: scroll: 滚动、 routeName：切换路由、link：链接外跳
            // target: 滚动的dom选择器或跳转链接
            // review: 是否判断审核状态，默认false
            {
                name: 'home',
                type: 'scroll',
                target: '#index',
            },
            {
                name: 'news',
                type: 'routeName',
                target: 'List',
            },
            {
                name: 'community',
                type: 'scroll',
                target: '#thirdParty',
            },
            {
                name: 'character',
                type: 'scroll',
                target: '#role',
            },
            {
                name: 'world',
                type: 'scroll',
                target: '#world',
            },
            {
                name: 'features',
                type: 'scroll',
                target: '#special',
            },
            {
                name: 'topUp',
                type: 'link',
                target: '//pay.mcdofficial.com/platform/officialWebStore.html?gameId=295&cid=official&scid=btn',
                review: true,
            },
            {
                name: 'customerService',
                type: 'link',
                target: '//service.mcdofficial.com/?gameId=295#/creatework',
            },
        ],
        safeDistance: 70, // 滚动安全区，单位px
        logo: new URL('@images/logo.png', import.meta.url),
        thirdParty: [ // 菜单需展示的社群，同时关联PC/移动端导航弹窗，当种类数量大于1个时，会在右侧展示
            {
                type: 'facebook',
                link: 'https://www.facebook.com/MechaDomination'
            },
            {
                type: 'discord',
                link: 'https://discord.gg/Jmp37GAwwE'
            }
        ],
    },
    lang: {
        show: true, // 是否有多语言
        list: [ //支持的语言选项
            {
                code: 'en-US',
                name: 'English'
            },
            {
                code: 'fr-FR',
                name: 'Français'
            },
            {
                code: 'de-DE',
                name: 'Deutsch'
            },
            {
                code: 'ru-RU',
                name: 'Русский'
            },
            {
                code: 'ja-JP',
                name: '日本語'
            },
            {
                code: 'zh-TW',
                name: '繁體中文'
            },
            {
                code: 'ko-KR',
                name: '한국어'
            },
        ],
    },
    downloadLink: { // 下载链接，ios/android/sdk 默认会优先从gameInfo接口读取
        appstore: '',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.dino.steel',
        pcPlay: '', // 微端下载链接
        apk: '',
        ldPlayer: '',
        yeshen: '',
        blueStack: '',
        default_qrcode: new URL('@images/list/qrcode.png', import.meta.url),
    },
    index: {  // 首屏相关配置
        videoLink: '',
        bgm: '', // 背景音乐
        slogan: '',
        showMoreDownload: false,
    },
    videoLink: '5JtUhN7DkWY', // 视频资讯
    news: {  // 新闻资讯/文章列表配置
        mainTitle: 'newsMainTitle', // 主标题
        defaultNewsPic: new URL('@images/news/news_article_preview.jpg', import.meta.url), // 新闻默认图片
        type: {
            'en-US': {
                '1919': 'ANNOUNCEMENT',
                '1926': 'EVENT',
                '1878': 'GUIDE'
            },
            'ru-RU': {
                '1924': 'ОБЪЯВЛЕНИЯ',
                '1931': 'СОБЫТИЯ',
                '1881': 'РУКОВОДСТВО'
            },
            'fr-FR': {
                '1923': 'ANNONCEMENT',
                '1930': 'ÉVÉNEMENT',
                '1879': 'GUIDE'
            },
            'de-DE': {
                '1922': 'ANKÜNDIGUNG',
                '1929': 'EVENT',
                '1880': 'GUIDE'
            },
            'ja-JP': {
                '1920': '公告',
                '1927': 'イベント',
                '1916': '攻略'
            },
            'zh-TW': {
                '1918': '公告',
                '1925': '活動',
                '1915': '攻略'
            },
            'ko-KR': {
                '1921': '공지',
                '1928': '이벤트',
                '1917': '공략'
            },
        }
    },
    thirdParty: {   // 社群相关配置
        supportArr: [ // 支持的社群列表
            {
                type: 'facebook',
                link: 'https://www.facebook.com/MechaDomination'
            },
        ],
        iframe: { // 只支持facebook/twitter
            type: 'facebook', // 显示的社群主页插件
            link: 'https://www.facebook.com/MechaDomination',
            appId: '',
        },
    },
    role: {  // 角色相关配置
        mainTitle: 'roleMainTitle', // 主标题
        subTitle: '', // 副标题
        swiperType: 'full', // full全屏 / normal普通
        langDiffs: true, // 是否包含多语言
        showSkillText: true,
        list: [
            {
                name: 'mcd', // 主模块名称
                roleInfo: [
                    {
                        name: 'monster1',
                        desc: 'monster1_text',
                        mediaType: '',
                        url: '',
                        avatar: new URL('@images/role/avatar_1.png', import.meta.url),
                        pic: new URL('@images/role/char_1.jpg', import.meta.url),
                        skill: [
                            {
                                name: 'monster1_skill1',
                                pic: new URL('@images/skill/role_1_1.png', import.meta.url),
                            },
                            {
                                name: 'monster1_skill2',
                                pic: new URL('@images/skill/role_1_2.png', import.meta.url),
                            },
                            {
                                name: 'monster1_skill3',
                                pic: new URL('@images/skill/role_1_3.png', import.meta.url),
                            },
                            {
                                name: 'monster1_skill4',
                                pic: new URL('@images/skill/role_1_4.png', import.meta.url),
                            }
                        ]
                    },
                    {
                        name: 'monster2',
                        desc: 'monster2_text',
                        cv: '',
                        mediaType: '',
                        url: '',
                        avatar: new URL('@images/role/avatar_2.png', import.meta.url),
                        pic: new URL('@images/role/char_2.jpg', import.meta.url),
                        skill: [
                            {
                                name: 'monster2_skill1',
                                pic: new URL('@images/skill/role_2_1.png', import.meta.url),
                            },
                            {
                                name: 'monster2_skill2',
                                pic: new URL('@images/skill/role_2_2.png', import.meta.url),
                            },
                            {
                                name: 'monster2_skill3',
                                pic: new URL('@images/skill/role_2_3.png', import.meta.url),
                            },
                            {
                                name: 'monster2_skill4',
                                pic: new URL('@images/skill/role_2_4.png', import.meta.url),
                            },
                        ]
                    },
                    {
                        name: 'monster3',
                        desc: 'monster3_text',
                        cv: '',
                        mediaType: '',
                        url: '',
                        avatar: new URL('@images/role/avatar_3.png', import.meta.url),
                        pic: new URL('@images/role/char_3.jpg', import.meta.url),
                        skill: [
                            {
                                name: 'monster3_skill1',
                                pic: new URL('@images/skill/role_3_1.png', import.meta.url),
                            },
                            {
                                name: 'monster3_skill2',
                                pic: new URL('@images/skill/role_3_2.png', import.meta.url),
                            },
                            {
                                name: 'monster3_skill3',
                                pic: new URL('@images/skill/role_3_3.png', import.meta.url),
                            },
                            {
                                name: 'monster3_skill4',
                                pic: new URL('@images/skill/role_3_4.png', import.meta.url),
                            },

                        ]
                    },
                    {
                        name: 'monster4',
                        desc: 'monster4_text',
                        cv: '',
                        mediaType: '',
                        url: '',
                        avatar: new URL('@images/role/avatar_4.png', import.meta.url),
                        pic: new URL('@images/role/char_4.jpg', import.meta.url),
                        skill: [
                            {
                                name: 'monster4_skill1',
                                pic: new URL('@images/skill/role_4_1.png', import.meta.url),
                            },
                            {
                                name: 'monster4_skill2',
                                pic: new URL('@images/skill/role_4_2.png', import.meta.url),
                            },
                            {
                                name: 'monster4_skill3',
                                pic: new URL('@images/skill/role_4_3.png', import.meta.url),
                            },
                            {
                                name: 'monster4_skill4',
                                pic: new URL('@images/skill/role_4_4.png', import.meta.url),
                            },

                        ]
                    },
                    {
                        name: 'monster5',
                        desc: 'monster5_text',
                        cv: '',
                        mediaType: '',
                        url: '',
                        avatar: new URL('@images/role/avatar_5.png', import.meta.url),
                        pic: new URL('@images/role/char_5.jpg', import.meta.url),
                        skill: [
                            {
                                name: 'monster5_skill1',
                                pic: new URL('@images/skill/role_5_1.png', import.meta.url),
                            },
                            {
                                name: 'monster5_skill2',
                                pic: new URL('@images/skill/role_5_2.png', import.meta.url),
                            },
                            {
                                name: 'monster5_skill3',
                                pic: new URL('@images/skill/role_5_3.png', import.meta.url),
                            },
                            {
                                name: 'monster5_skill4',
                                pic: new URL('@images/skill/role_5_4.png', import.meta.url),
                            },
                        ]
                    },
                ]
            },
        ]
    },
    world:{
        mainTitle: 'worldMainTitle', // 主标题
        subTitle: '', // 副标题
        worldText: [ // 世界相关配置
            [   'worldText1',
                'worldText2',
                'worldText3',
                'worldText4',
                'worldText5',
                'worldText6'
            ],
            [   'worldText1',
                'worldText2',
                'worldText3',
                'worldText4',
                'worldText5',
                'worldText6'
            ]
        ]
    },
    special: [ // 游戏特色相关配置
        {
            name: 'specialMainTitle', // 主模块名称
            list: [
                {
                    pic_default: new URL('@images/special/en/special_en_0.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_0.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_0.jpg', import.meta.url),
                },
                {
                    pic_default: new URL('@images/special/en/special_en_1.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_1.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_1.jpg', import.meta.url),
                },
                {
                    pic_default: new URL('@images/special/en/special_en_2.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_2.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_2.jpg', import.meta.url),
                },
                {
                    pic_default: new URL('@images/special/en/special_en_3.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_3.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_3.jpg', import.meta.url),
                },
                {
                    pic_default: new URL('@images/special/en/special_en_4.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_4.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_4.jpg', import.meta.url),
                },
                {
                    pic_default: new URL('@images/special/en/special_en_5.jpg', import.meta.url),
                    pic_US: new URL('@images/special/en/special_en_5.jpg', import.meta.url),
                    pic_DE: new URL('@images/special/de/special_de_5.jpg', import.meta.url),
                },
            ]
        },
    ],
    footer: {
        ageLimit: {
            show: false, // 是否展示年龄限制相关，若为false，可不填写对象内age及text内容
            age: '18', // -1:不展示  年龄分级：0 6 12 15 18
            text: [
                '*本遊戲內容涉及暴力（武俠動作）、角色穿著凸顯胸、臂之服飾',
                '*本遊戲部分內容需另行支付費用',
                '*請避免沉迷遊戲'
            ]
        },
        termArr: [ // 包含条款 $HOST: 主域名
            {
                name: 'fwtk',
                link: 'https://gpassport.$HOST/center/ServicePrivacy/service',
            },
            {
                name: 'yszc',
                link: 'https://gpassport.$HOST/center/ServicePrivacy/privacy',
            },
        ]
    },
    articleBanner: new URL('@images/list/list_banner.jpg', import.meta.url),
    payAside: {
        show: true,
        default_payLink: 'https://pay.mcdofficial.com/platform/officialWebStore.html?gameId=295&cid=official&scid=btn',
        payLink_US: 'https://auto-page.mcdofficial.com/js_zhanling_01/?cid=official&scid=sidebar',
        payLink_DE: 'https://auto-page.mcdofficial.com/js_zhanling_01/?cid=official&scid=sidebar',
        guidePage: 'https://auto-page.mcdofficial.com/mcdpayguide/',
        default_bg: new URL('@images/aside/pay_aside_default.png', import.meta.url),
        bg_US: new URL('@images/aside/pay_aside_US.png', import.meta.url),
        bg_DE: new URL('@images/aside/pay_aside_DE.png', import.meta.url),
        bg_FR: new URL('@images/aside/pay_aside_FR.png', import.meta.url),
        bg_RU: new URL('@images/aside/pay_aside_RU.png', import.meta.url),
        bg_JP: new URL('@images/aside/pay_aside_JP.png', import.meta.url),
        bg_TW: new URL('@images/aside/pay_aside_TW.png', import.meta.url),
        bg_KR: new URL('@images/aside/pay_aside_KR.png', import.meta.url),
    }
}
export default websiteConfig