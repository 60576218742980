export default {
  "home": "INICIO",
  "news": "NOTICIAS",
  "community": "COMUNIDAD",
  "character": "PERSONAJES",
  "world": "AJUSTES DEL MUNDO",
  "features": "CARACTERÍSTICAS",
  "topUp": "RECARGA",
  "customerService": "ATENCIÓN AL CLIENTE",
  "download": "DESCARGAR",
  "moreDownload": "Más métodos de descarga",
  "screenshot": "CAPTURA DE PANTALLA",
  "video": "CINEMÁTICA",
  "poster": "PÓSTER",
  "downloadGame": "DESCARGAR",
  "scan": "Escanear código QR para descargar",
  "prev": "Anterior",
  "next": "Siguiente",
  "moreNews": "INFORMACIÓN",
  "latest": "LO NUEVO",
  "fwtk": "TÉRMINOS DE SERVICIO",
  "yszc": "POLÍTICA DE PRIVACIDAD",
  "noContent": "Sin contenido aún",
  "goPay": "IR AHORA",
  "payGuide": "SABER MÁS",
  "stayTurn": "Por favor, espere",
  "back": "Volver",

}
