import jsonp from 'jsonp';
import type { resType } from '../types/index';
import { getCurrentHost } from '@/utils'
import websiteConfig from '@/configs/websiteConfig'
export const GAME_ID = websiteConfig.basic.gameId;
export const HOST = getCurrentHost();
export const BRAND = HOST.split('.')[0];
export const convertParamToString = (
    params: { [x: string]: string | number } | undefined
) => {
  const paramArray: string[] = [];
  for (const key in params) {
    let val = params[key];
    if ('object' === typeof val) {
      val = JSON.stringify(val);
    }
    const param = key + '=' + val;
    paramArray.push(param);
  }
  paramArray.push('callback');
  if (paramArray.length > 0) {
    return paramArray.join('&');
  }
  return '';
}
export const assignQueryParam = (target) => {
  // 合并链接上的参数
  const queryParams = new URLSearchParams(window.location.search)
  Object.keys(target).forEach(item => {
    queryParams.set(item, target[item])
  })
  return queryParams.toString();
}
export default function getJSONP<T>(url: string, params?: T): Promise<resType> {
  return new Promise(resolve => {
    jsonp(
      url,
      {
        prefix: 'jsonpCallback',
        param: params
          ? assignQueryParam({
              params: encodeURIComponent(JSON.stringify(params)),
            })
          : '',
      },
      (err, res) => {
        err ? alert(err) : resolve(res);
      }
    );
  });
}
