import websiteConfig from '@/configs/websiteConfig';
const GAME_ID = websiteConfig.basic.gameId;
const GAME_CODE = websiteConfig.basic.websiteId;
interface pointParamImpl {
  eventName: string;
  eventType: any;
  eventReason?: any;
  pageName?: string;
  popupName?: string;
  buttonName?: string;
  popupButtonName?: string;
}

const pointNameMap: any = {
  thirdParty: '社群',
  news: '新闻资讯',
  role: '角色介绍TAB',
  roleDetail: '角色介绍',
  special: '游戏特色',
  world: '世界观',
  aside: '悬浮窗',
  index: '首页',
  head: '顶部栏',
  'm-menu': '移动端导航',
  moreWay: '更多下载方式',
  payAside: '第三方支付悬浮窗',
};

export const clickBtnUpload = ({ target }: any): any => {
  const type = target.dataset.type;
  const reason = target.dataset.reason;
  if (!type || !reason) {
    return;
  }
  point({
    eventName: 'click_button',
    eventType: pointNameMap[type] || type,
    eventReason: pointNameMap[reason] || reason,
  });
};
export const point = (data: pointParamImpl) => {
  try {
    // 上报BI的
    (window as any).activityDataLayer.push({
      eventName: data.eventName,
      eventType: data.eventType,
      eventReason: data.eventReason,
      aid: 'xgw_' + GAME_CODE,
      gameId: GAME_ID,
    });
  } catch (error) {
    console.error(error);
  }

  // 上报GA的
  try {
    (window as any).dataLayer.push({
      event: 'creatEvt',
      manualEvent: data.eventType,
      customValue: data.eventReason,
    });
  } catch (error) {
    console.error(error);
  }
  (window as any).pointTest && console.log(data, GAME_ID);
};
