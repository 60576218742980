export default {
  "home": "HOME",
  "news": "NEWS",
  "community": "COMMUNITY",
  "character": "CHARACTERS",
  "world": "WORLD",
  "features": "FEATURES",
  "topUp": "TOP UP",
  "customerService": "CUSTOMER",
  "download": "DOWNLOAD",
  "moreDownload": "More download methods",
  "screenshot": "SCREENSHOT",
  "video": "CINEMATIC",
  "poster": "POSTER",
  "downloadGame": "DOWNLOAD",
  "scan": "Scan QR code to download",
  "prev": "Previous",
  "next": "Next",
  "latest": "LATEST",
  "moreNews": "INFORMATION",
  "fwtk": "TERMS OF SERVICE",
  "yszc": "PRIVACY POLICY",
  "noContent": "No content yet",
  "goPay": "GO NOW",
  "payGuide": "LEARN MORE",
  "stayTurn": "Stay tuned!",
  "monster1": "AGF D-01 Tyrant",
  "monster1_text": "It is a dominator-class beast created by Argeon, named after the despair it inflicts on humanity.",
  "monster1_skill1": "Fury Field",
  "monster1_skill2": "Magnetic Overload",
  "monster1_skill3": "High Voltage",
  "monster1_skill4": "Slaughter Core",
  "monster2": "BS y23c Caesar",
  "monster2_text": "The first bio-beast developed by humans through the combination of Argeonic technology and the genes of ancient creatures.",
  "monster2_skill1": "Strong One-Two Punch",
  "monster2_skill2": "Shattering Strike",
  "monster2_skill3": "Flanking Tactics",
  "monster2_skill4": "Bloodlust Core",
  "monster3": "AGF B-05A Spikeroller",
  "monster3_text": "It is a defensive fortress designed by Argeon for close combat, and it is anything but a docile herbivore.",
  "monster3_skill1": "Formation Break",
  "monster3_skill2": "Iron Wall",
  "monster3_skill3": "Flanking Tactics",
  "monster3_skill4": "Bloodlust Core",
  "monster4": "AGF A-03 Scorcher",
  "monster4_text": "It is a super-heavy, long-range artillery platform, a giant beast created by Argeon for long-range bombing.",
  "monster4_skill1": "Rocket Barrage",
  "monster4_skill2": "TNT",
  "monster4_skill3": "Invasion Tactics",
  "monster4_skill4": "Destruction Core",
  "monster5": "AAF I-02 Firespitter",
  "monster5_text": "It is an infiltration beast created by Argeon for reconnaissance purposes, with exceptional vision and flying speed.",
  "monster5_skill1": "Firewing Raid",
  "monster5_skill2": "Fire Sea",
  "monster5_skill3": "Quick Attack",
  "monster5_skill4": "Slaughter Core",
  "newsMainTitle": "GAME INFO",
  "roleMainTitle": "BEASTS",
  "worldMainTitle": "SAVE THE WORLD FROM MECHANIZED BEASTS",
  "specialMainTitle": "FEATURES",
  "worldText1": "How can humanity survive in a perilous wasteland overrun by rampaging mechanized beasts?",
  "worldText2": "Our once flourishing world was annihilated by colossal mechanized beasts,",
  "worldText3": "causing humans to be displaced wherever these beasts roamed.",
  "worldText4": "For centuries, this world has been plagued by wars and massacres, until you, a valiant commander emerges.",
  "worldText5": "You will lead the survivors to capture and modify beasts, train troops, form alliances,",
  "worldText6": "and ultimately save humanity's last remaining enclaves.",
  "back": "Back",
}
