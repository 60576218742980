import { ref } from "vue";
import websiteConfig from "@/configs/websiteConfig";
import tw from './zh-TW'
import en from './en-US'
import zh from './zh-CN'
import ar from './ar-SA'
import de from './de-DE'
import es from './es-ES'
import fr from './fr-FR'
import id from './id-ID'
import it from './it-IT'
import ja from './ja-JP'
import ko from './ko-KR'
import pl from './pl-PL'
import pt from './pt-PT'
import ru from './ru-RU'
import th from './th-TH'
import tr from './tr-TR'
import vi from './vi-VN'
import { getParam } from '@/utils/index.ts'
import { createI18n } from 'vue-i18n'
const LANG_MAP: any = {
  'zh-CN': zh,
  'zh-TW': tw,
  'en-US': en,
  'ar-SA': ar,
  'de-DE': de,
  'es-ES': es,
  'fr-FR': fr,
  'id-ID': id,
  'it-IT': it,
  'ja-JP': ja,
  'ko-KR': ko,
  'pl-PL': pl,
  'pt-PT': pt,
  'ru-RU': ru,
  'th-TH': th,
  'tr-TR': tr,
  'vi-VN': vi,
}
const langList = websiteConfig.lang.list.map(item => item.code);
export const nowLang = ref('');
const checkLanguage = (lang) => {
  let res = "";
  let languageList = Object.keys(LANG_MAP)
  if (lang) {
    if (lang.indexOf("-") > -1) {
      for (let i = 0; i < languageList.length; i++) {
        if (languageList[i] === lang) {
          res = languageList[i];
        }
      }
    } else {
      const reg = new RegExp(lang, "g");
      for (let i = 0; i < languageList.length; i++) {
        if (reg.test(languageList[i].toLowerCase())) {
          res = languageList[i];
        }
      }
    }
  }
  return langList.includes(res) ? res : langList[0];
};
export const i18n = createI18n({
  locale: nowLang.value,
  messages: LANG_MAP,
  silentTranslationWarn: true,
})

export const language = {
  lang: '',
  defaultLang: 'en-US',
  init() {
    // 获取localstorage的语言
    const localLanguage = localStorage.getItem(`${websiteConfig.basic.websiteId}-language`)
    const lang = this.getUrlLang() || localLanguage || this.getDefaultLang() || this.getBrowserLang()
    this.setLang(checkLanguage(lang))
  },
  setLang(lang: string) {
    document.getElementsByTagName("html")[0].setAttribute("dir", lang == "ar-SA" ? "rtl" : "ltr");
    nowLang.value = lang
    localStorage.setItem(`${websiteConfig.basic.websiteId}-language`, lang)
    i18n.global.locale = lang
    this.lang = lang
  },
  getDefaultLang() {
    return this.defaultLang
  },
  getUrlLang() {
    return getParam('appLanguage') || getParam('language')
  },
  getBrowserLang() {
    const lang = window.navigator.language
    if (lang.includes('-')) {
      const langArr = lang.split('-')
      const realLang = `${langArr[0].toLowerCase()}-${langArr[1].toUpperCase()}`
      return LANG_MAP[realLang] ? realLang : false
    } else {
      const realLang = lang.toLowerCase()
      let res = ''
      for (const key in LANG_MAP) {
        const langkey = key.split('-')[0]
        if (realLang === langkey) {
          res = key
          break
        }
      }
      return res
    }
  },
  getAllText() {
    return LANG_MAP[this.lang]
  },
  getTextByKey(key: string) {
    return LANG_MAP[this.lang][key] || ''
  },
  getLang() {
    return this.lang
  }
}
language.init()