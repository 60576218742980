import { getArticleDetail } from '@api/news';
import { useRoute, useRouter } from 'vue-router';
import { reactive, watchEffect } from 'vue';
import { point } from '@utils/pointUtils';
const articleDetail = reactive({
  articleId: '',
  articleTitle: '',
  articleTime: '',
  articleContent: '',
});

export default function () {
  const route = useRoute();
  const router = useRouter();
  const backToList = () => {
    point({
      eventName: 'click_button',
      eventType: '文章详情',
      eventReason: '返回列表'
    });
    router.push('/list');
  };
  point({
    eventName: 'view_page',
    eventType: '文章详情_' + route.query.id,
  });
  watchEffect(async () => {
    const id = route.query.id as string;
    if (!id) {
      route.path === '/detail' && router.push('/list');
      return;
    }

    const articleDetailRes = await getArticleDetail(id);
    if (+articleDetailRes.result === 1) {
      articleDetail.articleId = id;
      articleDetail.articleContent = articleDetailRes?.data?.CONTENT;
      articleDetail.articleTitle = articleDetailRes?.data?.TITLE;
      articleDetail.articleTime = (
        articleDetailRes?.data?.PUBLISH_TIME || ''
      ).slice(0, 10);
    }
  });

  return {
    articleDetail,
    backToList,
  };
}
