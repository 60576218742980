export default {
  "home": "ДОМОЙ",
  "news": "НОВОСТИ",
  "community": "СООБЩЕСТВО",
  "character": "ПЕРСОНАЖИ",
  "world": "НАСТРОЙКИ МИРА",
  "features": "ФУНКЦИИ",
  "topUp": "ПОПОЛНЕНИЕ",
  "customerService": "СЛУЖБА ПОДДЕРЖКИ",
  "download": "СКАЧАТЬ",
  "moreDownload": "Другие способы загрузки",
  "screenshot": "СКРИНШОТ",
  "video": "СИНЕМАТИК",
  "poster": "ПЛАКАТ",
  "downloadGame": "СКАЧАТЬ",
  "scan": "Отсканируйте QR-код, чтобы скачать",
  "prev": "Пред.",
  "next": "След.",
  "moreNews": "ИНФОРМАЦИЯ",
  "latest": "НОВИНКИ",
  "fwtk": "TERMS OF SERVICE",
  "yszc": "PRIVACY POLICY",
  "noContent": "Пока нет содержания",
  "goPay": "ВПЕРЁД",
  "payGuide": "УЗНАТЬ БОЛЬШЕ",
  "stayTurn": "Пока нет содержания",
  "monster1": "НСА Д-01 «Тиран»",
  "monster1_text": "Аргеон назвал своего зверя-доминатора во имя отчаяния, которое он вселяет в человечество.",
  "monster1_skill1": "Яростное поле",
  "monster1_skill2": "Магнитная перегрузка",
  "monster1_skill3": "Высокое напряжение",
  "monster1_skill4": "Рубящее ядро",
  "monster2": "БП γ23 «Цезарь»",
  "monster2_text": "Первый разработанный людьми био-зверь, полученный путём совмещения технологий Аргеона с генами древних существ",
  "monster2_skill1": "Двойной удар",
  "monster2_skill2": "Крушащая атака",
  "monster2_skill3": "Обходная тактика",
  "monster2_skill4": "Кровавое ядро",
  "monster3": "НСА Б-05А «Иглобрюх»",
  "monster3_text": "Ходячая крепость, построенная Аргеоном для ближнего боя. Называйте его как угодно, но только не послушным травоядным зверем.",
  "monster3_skill1": "Пролом строя",
  "monster3_skill2": "Стальная стена",
  "monster3_skill3": "Обходная тактика",
  "monster3_skill4": "Кровавое ядро",
  "monster4": "НСА А-03 «Выжигатель»",
  "monster4_text": "Это сверхтяжелая огневая платформа - гигантский зверь Аргеона, атакующий на дальней дистанции.",
  "monster4_skill1": "Ракетный обстрел",
  "monster4_skill2": "Взрывчатка",
  "monster4_skill3": "Штурмовая тактика",
  "monster4_skill4": "Крушащее ядро",
  "monster5": "ВСА Д-02 «Огнеплюй»",
  "monster5_text": "Зверь скрытного проникновения, созданный Аргеоном для разведывательных операций. Обладает отличным зрением и высокой скоростью полета.",
  "monster5_skill1": "Огнекрылый рейд",
  "monster5_skill2": "Пламенный океан",
  "monster5_skill3": "Быстрая атака",
  "monster5_skill4": "Рубящее ядро",
  "newsMainTitle": "ОПИСАНИЕ ИГРЫ",
  "roleMainTitle": "ЗВЕРИ",
  "worldMainTitle": "СПАСИТЕ МИР ОТ МЕХАЗАВРОВ",
  "specialMainTitle": "ОСОБЕННОСТИ",
  "worldText1": "Сможет ли человечество выжить в опасной пустоши, где повсюду бродят свирепые механические чудовища?",
  "worldText2": "Наш некогда процветающий мир был уничтожен.",
  "worldText3": "Механические звери вытеснили людей из всех мест обитания.",
  "worldText4": "Войны и кровопролитие царят на земле уже сотни лет... Но тут появились вы - храбрый и доблестный командир.",
  "worldText5": "Вы научите выживших захватывать и модифицировать зверей,",
  "worldText6": "будете обучать армию и заключать союзы, чтобы однажды спасти человечество.",
  "back": "Назад",
}
