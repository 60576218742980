export default {
  "home": "首頁",
  "news": "最新資訊",
  "community": "社群資訊",
  "character": "角色介紹",
  "world": "世界觀",
  "features": "遊戲特色",
  "topUp": "儲值購點",
  "customerService": "客服中心",
  "download": "立即下載",
  "moreDownload": "更多下載方式",
  "screenshot": "截圖",
  "video": "影片",
  "poster": "海報",
  "downloadGame": "下載遊戲",
  "scan": "掃一掃下載遊戲",
  "prev": "上一頁",
  "next": "下一頁",
  "moreNews": "更多資訊",
  "latest": "最新",
  "fwtk": "服務條款",
  "yszc": "隱私政策",
  "noContent": "暫無文章，敬請期待",
  "goPay": "立即前往",
  "payGuide": "瞭解詳情",
  "stayTurn": "敬請期待",
  "monster1": "AGF D-01狂怒暴君",
  "monster1_text": "統御者巨獸，是天網製造的首腦級巨獸，被絕望的人類賦予“暴君”之名。",
  "monster1_skill1": "狂怒力場",
  "monster1_skill2": "磁體超載",
  "monster1_skill3": "極限電壓",
  "monster1_skill4": "殺戮核心",
  "monster2": "BS y23c 白凱撒",
  "monster2_text": "推進型巨獸，是天網製造的近距離對步兵用巨獸，擁有連續重擊的能力。",
  "monster2_skill1": "金剛連拳",
  "monster2_skill2": "裂地強擊",
  "monster2_skill3": "迂回戰術",
  "monster2_skill4": "嗜血核心",
  "monster3": "AGF B-05D 重錘甲龍",
  "monster3_text": "防禦型堡壘，是天網製造的近距離突擊用巨獸，並不是溫順的食草動物。",
  "monster3_skill1": "堅甲破陣",
  "monster3_skill2": "鐵壁態勢",
  "monster3_skill3": "迂回戰術",
  "monster3_skill4": "嗜血核心",
  "monster4": "AGF A-03 轟擊猛獁",
  "monster4_text": "超重遠端火力平臺，是天網製造的巨型遠端轟炸巨獸，可以連續發射火箭彈。",
  "monster4_skill1": "火箭彈幕",
  "monster4_skill2": "高爆炸藥",
  "monster4_skill3": "侵略戰術",
  "monster4_skill4": "毀滅核心",
  "monster5": "AAF I-02 焚火翼龍",
  "monster5_text": "偵察型單位，是天網製造的滲透用巨獸，擁有極強的視覺與飛行速度",
  "monster5_skill1": "火翼急襲",
  "monster5_skill2": "火海燎原",
  "monster5_skill3": "快攻戰術",
  "monster5_skill4": "殺戮核心",
  "newsMainTitle": "遊戲資訊",
  "roleMainTitle": "巨獸展示",
  "worldMainTitle": "拯救世界，阻止機獸的威脅!",
  "specialMainTitle": "遊戲特色",
  "worldText1": "廢土世界，巨獸肆虐，人類如何生存？",
  "worldText2": "曾經繁榮的人類世界因合金巨獸的出現化為烏有，",
  "worldText3": "巨獸所到之處，人類流離失所。",
  "worldText4": "生存、逃亡、殺戮在這個世界迴圈了數百年，直到你——一位與眾不同的指揮官出現。",
  "worldText5": "你將帶領倖存者們狩獵巨獸、改造巨獸、訓練部隊、組建同盟，",
  "worldText6": "拯救人類最後的家園。",
  "back": "返回",
}
