<template>
  <transition name="fade">
    <div
      class="mobile-menu-popup"
      v-if="
        popupState.mobileMenu &&
        (mediaClass.screenType !== 'large' &&
          mediaClass.screenType !== 'tinyLand')
      "
    >
      <div class="popup-main">
        <a
          class="menu-item"
          :data-index="index + 1"
          href="javascript:;"
          v-for="(menu, index) in menuListMap"
          :key="index"
          @click="changeNav"
          >{{ $t(menu.name) }}</a
        >
        <div class="social" @click="clickBtnUpload">
          <a
            v-for="(thirdParty, thirdPartyIndex) in websiteConfig.menu.thirdParty"
            class="ib"
            :class="thirdParty.type"
            :data-type="thirdParty.type"
            :href="thirdParty.link"
            :key="thirdPartyIndex"
            target="_blank"
            data-reason="m-menu"
          ></a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import mediaClass from '@/hooks/queryMediaClass.ts';
import menuHook from '@/hooks/menuHook';
import popupHook from '@/hooks/popupHook';
import { clickBtnUpload } from '@/utils/pointUtils';
const { popupState } = popupHook();
const { menuListMap, changeNav, websiteConfig } = menuHook();
</script>
