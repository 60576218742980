import websiteConfig from '@/configs/websiteConfig'
import { reactive } from 'vue'
import { getGameInfo } from "@/api/gameInfo";
import { showTipsPopup } from '@/hooks/popupHook';
import { language } from "@/language";
import { useScriptTag } from '@vueuse/core';
let userAgent = window.navigator.userAgent;
let ua = userAgent.toLowerCase();
const showMoreDownload = websiteConfig.index.showMoreDownload
export const isIOS = ua.indexOf('iphone') > -1;
export const isAndroid = ua.indexOf('android') > -1;
// 获取配置的默认下载链接
export const gameInfo = reactive({
  apk: websiteConfig.downloadLink.apk,
  appstore: websiteConfig.downloadLink.appstore,
  googlePlay: websiteConfig.downloadLink.googlePlay,
  ldPlayer: websiteConfig.downloadLink.ldPlayer,
  pcPlay: websiteConfig.downloadLink.pcPlay,
  blueStack: websiteConfig.downloadLink.blueStack,
  qrCode: websiteConfig.downloadLink.default_qrcode,
});
export const updateGameInfo = () => {
  // 游戏下载链接以大麦配置为主!
  // 即gameInfo接口的数据为主，否则则读取websiteConfig的默认数据
  getGameInfo().then((gameInfoRes) => {
    if (+gameInfoRes.result === 1) {
      const gameData = gameInfoRes.data;
      gameData?.apkDownLoadURL?.length && (gameInfo.apk = gameData.apkDownLoadURL)
      gameData?.appStoreDownLoadURL?.length && (gameInfo.appstore = gameData.appStoreDownLoadURL)
      gameData?.googlePlayDownLoadURL?.length && (gameInfo.googlePlay = gameData.googlePlayDownLoadURL)
      gameData?.qrCode?.length && (gameInfo.qrCode = gameData.qrCode)
    }
  })
  // 初始化微端链接
  if ( gameInfo.pcPlay.length ) {
    useScriptTag(
        '//abres.octlib.com/common/js/hw.client.download.js',
        () => {
          window.initClientDownload({
            gameId: websiteConfig.basic.gameId, // 配置gameId
            link: gameInfo.pcPlay, // 配置微端下载链接
            packageName: websiteConfig.basic.packageName, //配置微端的包名
            className: 'j-client-download', // 配置点击下载的按钮的类名，微端下载按钮请使用<a>标签
          })
        }
    )
  }
}
export default function () {
  const jumpLink = ({ target }: any) => {
    const link = target.dataset.link;
    const href = target.getAttribute('href')
    // 如果href非js语句则不执行，比如微端脚本修改href数据
    if (href !== 'javascript:;' && href !== 'javascript:void(0);') {
      return;
    }
    if (!link) {
      showTipsPopup(language.getTextByKey('stayTurn'));
      return;
    }
    window.open(link, '_blank');
  }
  return {
    isAndroid,
    jumpLink,
    downloadLink: gameInfo,
    showMoreDownload
  };
}
