import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { BRAND, HOST } from '@/api/request';
import websiteConfig from '@/configs/websiteConfig';
export default function () {
    const route = useRoute();
    const isDetailPage = computed(() => {
        return route.name === 'Detail';
    });
    const copyright = `© COPYRIGHT ${new Date().getFullYear()} ${BRAND.toUpperCase()} ALL RIGHTS RESERVED.`;
    const termArr = websiteConfig.footer.termArr.map(item => {item.link = item.link.replace('$HOST', HOST); return item})
    const ageLimit = websiteConfig.footer.ageLimit
    return {
        BRAND,
        isDetailPage,
        copyright,
        termArr,
        ageLimit
    }
}