export default {
  "home": "หน้าหลัก",
  "news": "ข่าวล่าสุด",
  "community": "กรุ๊ปโซเชียล",
  "character": "ข้อมูลตัวละคร",
  "world": "ภายในเกม",
  "features": "ฟีเจอร์เกม",
  "topUp": "เติมเงิน",
  "customerService": "บริการลูกค้า",
  "download": "ดาวน์โหลด",
  "moreDownload": "ช่องทางดาวน์โหลดอื่นๆ",
  "screenshot": "รูปภาพ",
  "video": "วีดีโอ",
  "poster": "โปสเตอร์",
  "downloadGame": "ดาวน์โหลดเกม",
  "scan": "สแกน ดาวน์โหลดเกม",
  "prev": "หน้าที่แล้ว",
  "next": "หน้าถัดไป",
  "moreNews": "ข่าวเพิ่มเติม",
  "latest": "ล่าสุด",
  "fwtk": "Điều khoản phục vụ",
  "yszc": "Chính sách riêng tư",
  "noContent": "Chưa có bài viết, hãy chờ đón",
  "goPay": "ไป",
  "payGuide": "ดูเพิ่มเติม",
  "stayTurn": "คอยติดตาม",
  "back": "กลับ",

}
