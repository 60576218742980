export default {
  "home": "홈",
  "news": "최신 정보",
  "community": "커뮤니티",
  "character": "캐릭터",
  "world": "세계관",
  "features": "게임 소개",
  "topUp": "충전",
  "customerService": "고객센터",
  "download": "다운로드",
  "moreDownload": "더 보기",
  "screenshot": "스크린샷",
  "video": "동영상",
  "poster": "포스터",
  "downloadGame": "다운로드",
  "scan": "QR코드를 스캔하세요",
  "prev": "이전",
  "next": "다음",
  "moreNews": "더 보기",
  "latest": "최신",
  "fwtk": "서비스 약관",
  "yszc": "개인정보 취급 방침",
  "noContent": "준비 중",
  "goPay": "이동",
  "payGuide": "확인",
  "stayTurn": "준비 중",
  "monster1": "AGF D-01 타이런트",
  "monster1_text": "이클립스가 만든 근접형 통치용 비스트. 절망에 빠진 인류에 의해 ‘폭군’이라는 이름을 얻었다.",
  "monster1_skill1": "분노의 역장",
  "monster1_skill2": "자기체 과부화",
  "monster1_skill3": "극한 전압",
  "monster1_skill4": "살육 코어",
  "monster2": "BS y23c 알비노 시저",
  "monster2_text": "BS y23c 알비노 시저",
  "monster2_skill1": "연속 펀치",
  "monster2_skill2": "지면 강타",
  "monster2_skill3": "우회 전술",
  "monster2_skill4": "블러드 코어",
  "monster3": "AGF B-05D스피크롤라",
  "monster3_text": "방어형 보루. 이클립스가 만든 근접 돌격용 비스트. 초식 동물처럼 온순하지 않다.",
  "monster3_skill1": "갑옷 격파",
  "monster3_skill2": "철벽태세",
  "monster3_skill3": "우회 전술",
  "monster3_skill4": "블러드 코어",
  "monster4": "AGF A-03 스코처",
  "monster4_text": "초중량 장거리 화력 플랫폼으로 이클립스가 만든 초대형 원거리 폭격 비스트. 연속적으로 로켓을 발사할 수 있다.",
  "monster4_skill1": "로켓사격",
  "monster4_skill2": "고성능 폭약",
  "monster4_skill3": "침략 전술",
  "monster4_skill4": "파멸 코어",
  "monster5": "AAF I-02 파이어 스피터",
  "monster5_text": "이클립스가 만든 침투용 비스트. 정찰형 유닛으로 뛰어난 시각과 비행 속도를 가지고 있다.",
  "monster5_skill1": "불의 습격",
  "monster5_skill2": "대지의 불길",
  "monster5_skill3": "속공 전술",
  "monster5_skill4": "살육 코어",
  "newsMainTitle": "게임 정보",
  "roleMainTitle": "비스트 소개",
  "worldMainTitle": "잔혹한 세계에 도전하라, 메카 비스트와 함께!",
  "specialMainTitle": "게임 소개",
  "worldText1": "폐허가 된 세상, 비스트들의 횡포가 난무하는 가운데 과연 인간은 생존할 수 있을 것인가?",
  "worldText2": "번영을 자랑하던 인류의 터전이 합금 비스트의 출현으로 황폐해지고,",
  "worldText3": "그들을 피해 인간들은 의지할 곳 없이 유랑한다.",
  "worldText4": "생존, 도피, 살육이 세상을 지배한 지도 어언 수백 년, 비범한 지휘관, 바로 당신이 등장하는데...",
  "worldText5": "생존자들을 이끌고 비스트 사냥, 비스트 개조, 부대 훈련,",
  "worldText6": "연맹 결성을 통해 인류의 마지막 터전을 구하라!",
  "back": "돌아가기",
}
