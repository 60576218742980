<template>
  <div class="detail-page">
    <Banner></Banner>
    <div class="main">
      <div v-if="articleDetail.articleTitle.length">
        <div class="decorator"></div>
        <p class="title"
          >{{ articleDetail.articleTitle }}
          <a href="javascript:void(0)" class="back" @click="backToList">&lt</a>
        </p>
        <p class="time"> [{{ articleDetail.articleTime }}] </p>
        <div class="split"></div>
        <div class="content" v-html="articleDetail.articleContent"> </div>
      </div>
    </div>
    <div class="bottom">
      <a href="javascript:void(0)" class="back-btn ib" @click="backToList">
        &lt&lt {{ $t('back') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import detailHook from '@/hooks/detailHook';
import Banner from '@/components/list/Banner.vue';
const { articleDetail, backToList } = detailHook();
</script>
