export default {
  "home": "ACCUEIL",
  "news": "NOUVELLES",
  "community": "COMMUNAUTÉ",
  "character": "PERSONNAGES",
  "world": "CADRE DU MONDE",
  "features": "FEATURES",
  "topUp": "TOP UP",
  "customerService": "SERVICE CLIENT",
  "download": "TÉLÉCHARGER",
  "moreDownload": "Plus de méthodes de téléchargement",
  "screenshot": "CAPTURE D'ÉCRAN",
  "video": "CINÉMATIQUE",
  "poster": "POSTER",
  "downloadGame": "TÉLÉCHARGER",
  "scan": "Scanner le code QR pour télécharger",
  "prev": "Précédent",
  "next": "Suivant",
  "moreNews": "INFORMATIONS",
  "latest": "DERNIER",
  "fwtk": "TERMS OF SERVICE",
  "yszc": "PRIVACY POLICY",
  "noContent": "Encore aucun contenu",
  "goPay": "ALLER MAINTENANT",
  "payGuide": "EN SAVOIR PLUS",
  "stayTurn": "Restez à l’affût !",
  "monster1": "FTA D-01 Tyran",
  "monster1_text": "C'est une bête de classe dominator créée par Argeon. Son nom vient du désespoir qu'elle instille dans le cœur des humains.",
  "monster1_skill1": "Champ de furie",
  "monster1_skill2": "Surcharge magnétique",
  "monster1_skill3": "Haute tension",
  "monster1_skill4": "Noyau de massacre",
  "monster2": "BS y23c César",
  "monster2_text": "La première bio-bête développée par les humains grâce à la combinaison de la technologie argeonique et des gènes de créatures ancestrales.",
  "monster2_skill1": "Double revers",
  "monster2_skill2": "Assaut dévastateur",
  "monster2_skill3": "Tactiques de débordement",
  "monster2_skill4": "Noyau de soif de sang",
  "monster3": "FTA B-05A Embrocheur",
  "monster3_text": "Loin d'être un paisible herbivore, il s'agit d'une véritable plateforme défensive conçue pour le combat au corps à corps.",
  "monster3_skill1": "Briser la formation",
  "monster3_skill2": "Mur de fer",
  "monster3_skill3": "Tactiques de débordement",
  "monster3_skill4": "Noyau de soif de sang",
  "monster4": "FTA A-03 Écraseur",
  "monster4_text": "Cette bête géante est une véritable plateforme d'artillerie lourde dévastatrice à longue portée.",
  "monster4_skill1": "Barrage de roquettes",
  "monster4_skill2": "TNT",
  "monster4_skill3": "Tactiques d'invasion",
  "monster4_skill4": "Noyau de destruction",
  "monster5": "FAA I-02 Crache-feu",
  "monster5_text": "C'est une bête conçue pour l'infiltration et la reconnaissance, elle dispose d'une vision et d'une vitesse exceptionnelles.",
  "monster5_skill1": "Assaut aile de feu",
  "monster5_skill2": "Mer de feu",
  "monster5_skill3": "Attaque rapide",
  "monster5_skill4": "Noyau de massacre",
  "newsMainTitle": "INFOS DU JEU",
  "roleMainTitle": "BÊTES",
  "worldMainTitle": "SAUVEZ LE MONDE DES BÊTES MÉCANISÉES",
  "specialMainTitle": "FONCTIONNALITÉS",
  "worldText1": "Comment l’humanité peut-elle survivre dans un désert périlleux envahi par des bêtes mécanisées déchaînées ?",
  "worldText2": "Notre monde autrefois florissant a été anéanti par de colossales bêtes mécanisées qui forcent les peuples humains à s'enfuir sur leur passage.",
  "worldText3": "Pendant des siècles, ce monde a été en proie aux guerres et aux massacres,",
  "worldText4": "jusqu’à ce qu'un vaillant commandant émerge.",
  "worldText5": "Vous dirigerez les survivants et leur apprendrez à capturer et modifier des bêtes, à former des troupes,",
  "worldText6": "des alliances et à sauver les dernières enclaves de l’humanité.",
  "back": "Retour",
}
