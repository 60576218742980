import getJSONP, {assignQueryParam, HOST} from './request';
import type { IArticleParam } from './type';
import { getPlatform } from "@/utils";
const PLATFORM = getPlatform();
const getArticleListMap = {
  global: `//www.${HOST}/default/ajax/getArticals`,
  gm99: '//m.gm99.com/ajax/article_list',
};
const getArticleDetailMap = {
  global: `//www.${HOST}/article/ajax_article_detail`,
  gm99: '//m.gm99.com/ajax/article_content',
};

/**
 * 获取文章列表接口
 * ids:文章id，多个则逗号分隔
 * orderBy：返回的数据的排序规则
 * orderWay：降序或者升序，"DESC"或者"ASC"
 * gameId：游戏id
 * page:当前第几页
 * num:每页显示条数
 */
export const getArticle = ({
  ids,
  orderBy,
  orderWay,
  gameId,
  page,
}: IArticleParam) => {
  const params = {
    url: `${getArticleListMap[PLATFORM]}`,
    data: {
      ids,
      orderBy,
      orderWay,
      page,
      gameID: gameId,
    },
  };
  return getJSONP(`${params.url}?${assignQueryParam(params.data)}`)
      .then((res: any) => {
        if (PLATFORM === 'gm99' && res?.data?.list) {
          res.data.data = res.data.list;
          delete res.data.list;
        }
        return Promise.resolve(res);
      });
};

// 获取文章详情
export function getArticleDetail(id: string | number) {
  return getJSONP(`${getArticleDetailMap[PLATFORM]}${PLATFORM === 'global' ? '?id=' : '/id/'}${id}`);
}
