export default {
  "home": "ANASAYFA",
  "news": "HABERLER",
  "community": "TOPLULUK",
  "character": "KARAKTERLER",
  "world": "DÜNYA AYARI",
  "features": "ÖZELLİKLER",
  "topUp": "YÜKLEME",
  "customerService": "MÜŞTERİ HİZMETİ",
  "download": "İNDİR",
  "moreDownload": "Daha fazla indirme yöntemi",
  "screenshot": "EKRAN RESMİ",
  "video": "SİNEMATİK",
  "poster": "AFİŞ",
  "downloadGame": "İNDİR",
  "scan": "İndirmek için QR kodu okut",
  "prev": "Önceki",
  "next": "Sonraki",
  "moreNews": "BİLGİ",
  "latest": "EN SON",
  "fwtk": "KULLANIM ŞARTLARI",
  "yszc": "GİZLİLİK POLİTİKASI",
  "noContent": "İçerik yok",
  "goPay": "ŞİMDİ GİT",
  "payGuide": "DAHA FAZLA BİLGİ EDİN",
  "stayTurn": "bizi izlemeye devam edin",
  "back": "Geri",

}
