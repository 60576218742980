export default {
  "home": "DOM",
  "news": "WIADOMOŚCI",
  "community": "SPOŁECZNOŚĆ",
  "character": "POSTACIE",
  "world": "ŚWIAT",
  "features": "SZCZEGÓŁY",
  "topUp": "DOŁADOWANIE",
  "customerService": "OBSŁUGA KLIENTA",
  "download": "POBIERZ",
  "moreDownload": "Więcej sposób pobierania",
  "screenshot": "ZRZUTY EKRANU",
  "video": "FILMIKI",
  "poster": "PLAKAT",
  "downloadGame": "POBIERZ",
  "scan": "Zeskanuj kod QR i pobierz",
  "prev": "Poprzedni",
  "next": "Następny",
  "moreNews": "INFORMACJE",
  "latest": "NAJNOWSZE",
  "fwtk": "WARUNKI ŚWIADCZENIA USŁUG",
  "yszc": "POLITYKA PRYWATNOŚCI",
  "noContent": "",
  "goPay": "SPRAWDŹ TERAZ",
  "payGuide": "DOWIEDZ SIĘ WIĘCEJ",
  "stayTurn": "Prosimy o cierpliwość i oczekiwanie na dalsze informacje",
  "back": "powrót",

}
