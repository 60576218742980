export default {
  "home": "Trang đầu",
  "news": "Tin tức mới nhất",
  "community": "Thông tin fanpage",
  "character": "Giới thiệu nhân vật",
  "world": "Thế giới quan",
  "features": "Điểm nổi bật",
  "topUp": "Điểm nạp mua",
  "customerService": "CSKH",
  "download": "Tiếng Thái",
  "moreDownload": "Các tải khác",
  "screenshot": "Ảnh",
  "video": "Video",
  "poster": "Poster",
  "downloadGame": "Tải game",
  "scan": "Quét tải game",
  "prev": "Trang trước",
  "next": "Trang kế",
  "moreNews": "Tin tức khác",
  "latest": "Mới",
  "fwtk": "Điều khoản phục vụ",
  "yszc": "Chính sách riêng tư",
  "noContent": "Chưa có bài viết, hãy chờ đón",
  "goPay": "",
  "payGuide": "",
  "stayTurn": "hãy chờ đón",
  "back": "Quay về",
}
