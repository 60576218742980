import { reactive } from 'vue';
import { point } from '@/utils/pointUtils';
const popupState = reactive({
  downloadWay: false, // 首屏下载弹窗
  mobileMenu: false,  // 移动端目录弹窗
  iframeLink: '', // iframe弹窗
  currentYoutubeLink: '', // 当前youtube链接，如有内容则展示弹窗
  tips: '', // 当前tips，若有内容则展示弹窗
});
const hidePopup = () => {
  popupState.downloadWay = false;
  popupState.mobileMenu = false;
  popupState.iframeLink = '';
  popupState.currentYoutubeLink = '';
  popupState.tips = '';
};
const showPopup = type => {
  popupState[type] = true;
};
const togglePopup = type => {
  popupState[type] = !popupState[type];
  if (popupState[type] && type === 'mobileMenu') {
    point({
      eventName: 'view_page',
      eventType: '展开导航栏',
    });
  }
};
export const showYoutubeVideo = (link = '') => {
  popupState.currentYoutubeLink = link;
};
export const showIframePopup = (link  = '') => {
  popupState.iframeLink = link;

}
export const showTipsPopup = (tips = '') => {
  popupState.tips = tips;
}
export default function () {
  return {
    popupState,
    hidePopup,
    showPopup,
    togglePopup,
    showIframePopup,
    showYoutubeVideo,
    showTipsPopup,
  };
}
