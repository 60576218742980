import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { point } from '@utils/pointUtils';
import { isAndroid, gameInfo} from './gameInfoHook';
import { getParam } from '@/utils';
import mediaClass  from './queryMediaClass';
import popupHook from './popupHook';
import websiteConfig from '@/configs/websiteConfig';
import { HOST } from '@/api/request';
import { showTipsPopup } from '@/hooks/popupHook';
import { language } from "@/language";
const safeDistance = websiteConfig.menu.safeDistance; // 导航栏滚动安全区域
const isStore = +getParam('store') === 1

const menuListMap = websiteConfig.menu.list.filter(item => { // 顶部导航栏配置
  return isStore || !item.review; // 链接上不带参数store=1时，则判断是否审核模式，审核模式则不显示
}).map(item => {item.target = item.target.replace('$HOST', HOST); return item});

const downloadSlideActive = ref(false); // 下载链接下拉状态
let isMovingPage = false; // 页面是否滚动中
export default function () {
  const { hidePopup } = popupHook();
  const route = useRoute();
  const router = useRouter();
  // 获取元素所在的页面位置
  const getElePosition = (ele: Element) => {
    if (!ele || !ele.getBoundingClientRect) {
      return {
        eleTop: 0,
        eleBottom: 0,
      };
    }
    const { top, height } = ele.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      eleTop: top + scrollTop,
      eleBottom: top + scrollTop + height,
    };
  };
  // 移动页面
  const movePage = (distance: number) => {
    isMovingPage = true;
    movePageBySmooth(distance)
      .catch(() => {
        if (document.documentElement.scrollTop) {
          document.documentElement.scrollTop = distance;
        } else {
          document.body.scrollTop = distance;
        }
      })
      .finally(() => {
        setTimeout(() => {
          isMovingPage = false;
        }, 1200);
      });
  };

  // 平缓移动页面
  const movePageBySmooth = (distance: number) => {
    return new Promise((resolve, reject) => {
      try {
        window.scrollTo({
          top: distance,
          behavior: 'smooth',
        });
        resolve(true);
      } catch (e) {
        reject();
      }
    });
  };
  // 立即下载按钮
  const mobileHeadDownloadClick = () => {
    if (mediaClass.screenType !== 'large' && mediaClass.screenType !== 'tinyLand') {
      let link = isAndroid ? gameInfo.googlePlay : gameInfo.appstore;
      if(!link){
        //敬请期待
        showTipsPopup(language.getTextByKey('stayTurn'));
        return;
      }
      window.open(link, '_blank');
      point({
        eventName: 'click_button',
        eventReason: '导航栏',
        eventType: '下载按钮',
      });
    }
  }
  // 处理导航点击事件
  const navEventHandler = (type, target) => {
    // link: 链接跳转
    // routeName： 路由切换
    // scroll： 页面滚动
    const navEventMap = {
      link: () => {
        window.open(target, '_blank');
      },
      routeName: () => {
        router.push({
          name: target,
        })
      },
      scroll: () => {
        route.name !== 'home'
        &&
        router.push({
          name: 'Home',
        });
        setTimeout(() => {
          const targetSectionTop = getElePosition(document.querySelector(target)).eleTop;
          movePage(targetSectionTop - safeDistance);
        },20)
      }
    }
    navEventMap[type]();
  }
  // 切换导航栏
  const changeNav = ({ target }) => {
    const index = +target.dataset.index;
    if (index < 1) {
      return;
    }
    const nowNavItem = menuListMap[index - 1];
    const eventType = ['scroll','routeName','link']
    const type = nowNavItem.type;
    if (!eventType.includes(type)) {
      return;
    }
    point({
      eventName: 'click_button',
      eventReason: '导航栏',
      eventType: nowNavItem.name,
    });
    navEventHandler(type, nowNavItem.target)
    hidePopup();
  };
  return {
    changeNav,
    downloadSlideActive,
    menuListMap,
    mobileHeadDownloadClick,
    downloadLink: gameInfo,
    websiteConfig
  };
}
