export default {
  "home": "الصفحة الرئيسية",
  "news": "الأخبار",
  "community": "مجتمع اللاعبين",
  "character": "الشخصيات",
  "world": "الإعدادات العالمية",
  "features": "الخصائص",
  "topUp": "الشحن",
  "customerService": "خدمة العملاء",
  "download": "تحميل",
  "moreDownload": "المزيد من طرق التحميل",
  "screenshot": "لقطة للشاشة",
  "video": "سينمائي",
  "poster": "لافتة",
  "downloadGame": "تحميل",
  "scan": "امسح رمز QR للتحميل",
  "prev": "السابق",
  "next": "التالي",
  "moreNews": "المزيد من المعلومات",
  "latest": "الأحدث",
  "fwtk": "شروط الخدمة",
  "yszc": "سياسة الخصوصية",
  "noContent": "لا يوجد محتوى بعد",
  "goPay": "اذهب",
  "payGuide": "التفاصيل",
  "stayTurn": "انتظروا المزيد من ",
  "back": "عودة",

}
