import websiteConfig from '@/configs/websiteConfig';
import { ref, computed } from "vue";
import { language, nowLang } from "@/language";
const langList = websiteConfig.lang.list; // 顶部语言下拉选项配置
const langSlideActive = ref(false);  // 语言列表下拉状态
export default function () {
    const changeLang = (lang) => {
        language.setLang(lang)
    }
    const currentLanguage = computed(() => {
        return langList.find(item => item.code === nowLang.value)?.name || ''
    })
    return {
        langList,
        langSlideActive,
        changeLang,
        currentLanguage,
        showLang: websiteConfig.lang.show
    }
}