export default {
  "home": "STARTSEITE",
  "news": "NEUIGKEITEN",
  "community": "COMMUNITY",
  "character": "CHARAKTERE",
  "world": "WELT EINSTELLUNGEN",
  "features": "FUNKTIONEN",
  "topUp": "AUFLADEN",
  "customerService": "KUNDENDIENST",
  "download": "DOWNLOAD",
  "moreDownload": "Weitere Download-Methoden",
  "screenshot": "SCREENSHOT",
  "video": "FILMISCH",
  "poster": "POSTER",
  "downloadGame": "DOWNLOAD",
  "scan": "Zum Download QR-Code scannen.",
  "prev": "Vorher",
  "next": "Nächstes",
  "moreNews": "INFORMATION",
  "latest": "NEUESTE",
  "fwtk": "NUTZUNGSBEDINGUNGEN",
  "yszc": "DATENSCHUTZ-BESTIMMUNGEN",
  "noContent": "Noch kein Inhalt",
  "goPay": "LOS GEHT‘S",
  "payGuide": "MEHR ERFAHREN",
  "stayTurn": "Bleib dran!",
  "monster1": "AGF D-01 Tyrann",
  "monster1_text": "Es ist eine von Argeon geschaffene Bestie der Beherrscher-Klasse, benannt nach der Verzweiflung, die es der Menschheit zufügt.",
  "monster1_skill1": "Zornfeld",
  "monster1_skill2": "Magnetische Überlastung",
  "monster1_skill3": "Hochspannung",
  "monster1_skill4": "Schlachtkern",
  "monster2": "BS y23c Cäsar",
  "monster2_text": "Die erste Bio-Bestie, die von Menschen durch die Kombination argeonischer Technologie und der Gene uralter Kreaturen entwickelt wurde.",
  "monster2_skill1": "Starker Doppelschlag",
  "monster2_skill2": "Erschütternder Schlag",
  "monster2_skill3": "Flankentaktik",
  "monster2_skill4": "Blutrausch-Kern",
  "monster3": "AGF B-05A Stachelwälzer",
  "monster3_text": "Es handelt sich um eine von Argeon für den Nahkampf konzipierte Verteidigungsfestung, die alles andere als ein schwacher Pflanzenfresser ist.",
  "monster3_skill1": "Formationsbruch",
  "monster3_skill2": "Eisenwand",
  "monster3_skill3": "Flankentaktik",
  "monster3_skill4": "Blutrausch-Kern",
  "monster4": "AGF A-03 Verbrenner",
  "monster4_text": "Es handelt sich um eine superschwere Artillerieplattform mit großer Reichweite, eine riesige Bestie, die von Argeon für Bombenangriffe auf große Entfernungen entwickelt wurde.",
  "monster4_skill1": "Raketenbeschuss",
  "monster4_skill2": "TNT",
  "monster4_skill3": "Invasionstaktiken",
  "monster4_skill4": "Zerstörungskern",
  "monster5": "AAF I-02 Feuerspeier",
  "monster5_text": "Es handelt sich um eine von Argeon zu Aufklärungszwecken geschaffene Infiltrationsbestie mit außergewöhnlicher Sicht und Fluggeschwindigkeit.",
  "monster5_skill1": "Feuerflügel-Überfall",
  "monster5_skill2": "Feuermeer",
  "monster5_skill3": "Schnellangriff",
  "monster5_skill4": "Schlachtkern",
  "newsMainTitle": "SPIEL-INFO",
  "roleMainTitle": "BESTIEN",
  "worldMainTitle": "RETTE DIE WELT VOR MECHANISIERTEN BESTIEN",
  "specialMainTitle": "FUNKTIONEN",
  "worldText1": "Wie kann die Menschheit in einem gefährlichen Ödland überleben, das von wütenden, mechanisierten Bestien überrannt wird?",
  "worldText2": "Unsere einst blühende Welt wurde von kolossalen mechanisierten Bestien ausgelöscht,",
  "worldText3": "die die Menschen überall dorthin vertrieben, wo diese Bestien umherstreiften.",
  "worldText4": "Seit Jahrhunderten wird diese Welt von Kriegen und Massakern geplagt, bis du, ein tapferer Kommandant, auftauchst.",
  "worldText5": "Du wirst die Überlebenden anführen, um die Bestien zu ergreifen und zu modifizieren, Truppen trainieren,",
  "worldText6": "Allianzen schließen und schließlich die letzten verbliebenen Enklaven der Menschheit retten.",
  "back": "Zurück",
}
