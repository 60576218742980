<template>
  <div
    class="lang-select limit-px ib"
    @mouseover="langSlideActive = true"
    @mouseleave="langSlideActive = false"
  >
    <span class="currentLanguage">{{currentLanguage}}</span>
    <div class="lang-select-list" :class="{ active: langSlideActive }">
      <a
        href="javascript:void(0)"
        class="lang-option"
        v-for="(item, index) in langList"
        :key="index"
        @click="changeLang(item.code)"
        >{{ item.name }}</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import langHook from '@/hooks/langHook';
const { langSlideActive, langList, changeLang, currentLanguage} = langHook();
</script>
