<template>
  <transition name="fade">
    <div class="popup youtube-popup" v-if="popupState.currentYoutubeLink">
      <div class="popup-mask fade-in" @click="hidePopup()"></div>
      <div class="popup-main">
        <a
          href="javascript:void(0);"
          class="popup-main-close"
          @click="hidePopup()"
        ></a>
        <iframe
          width="100%"
          height="100%"
          title="youtubeIframe"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          :src="
            'https://www.youtube.com/embed/' +
            popupState.currentYoutubeLink +
            '?rel=0&playsinline=1&showinfo=0&disablekb=1&autoplay=1&modestbranding=1'
          "
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import popupHook from '@/hooks/popupHook';
const { popupState, hidePopup } = popupHook();
</script>
