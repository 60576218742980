import { createApp } from "vue";
import { i18n } from "@/language";
import websiteConfig from '@/configs/websiteConfig.ts';
import App from "../App.vue";
import router from '../router/router'
const app = createApp(App);
app.config.globalProperties.$websiteConfig = websiteConfig;
app.use(router)
app.use(i18n)
app.mount("#app");
