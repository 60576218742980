import { point } from '@/utils/pointUtils';
import { defineAsyncComponent } from "vue";
import websiteConfig from '@/configs/websiteConfig.ts';
import { updateGameInfo } from "@/hooks/gameInfoHook";
import { getParam } from '@/utils';
const isStore = +getParam('store') === 1
const showPayAside = websiteConfig.payAside.show && (websiteConfig.basic.review ? isStore : true);
export default function () {
    point({
        eventName: 'view_page',
        eventType: '官网主页',
    });
    updateGameInfo();
    const routeMap = {
        loading: defineAsyncComponent(() =>
            import('@/components/section/Loading.vue')),
        index: defineAsyncComponent(() =>
            import('@/components/section/Index.vue')),
        video: defineAsyncComponent(() =>
            import('@/components/section/Video.vue')),
        news: defineAsyncComponent(() =>
            import('@/components/section/News.vue')),
        thirdParty: defineAsyncComponent(() =>
            import('@/components/section/ThirdParty.vue')),
        role: defineAsyncComponent(() =>
            import('@/components/section/Role.vue')),
        world: defineAsyncComponent(() =>
            import('@/components/section/World.vue')),
        special: defineAsyncComponent(() =>
            import('@/components/section/Special.vue')),
    }
    return {
        sectionArr: websiteConfig.section,
        routeMap,
        showPayAside
    }
}