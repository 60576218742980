export const getParam = (name: string, href?: string): string => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let realparams = decodeURIComponent(window.location.search.substr(1));
  if (href && href.indexOf('?') > -1) {
    realparams = href.split('?')[1];
  }
  const r = realparams.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return '';
};

export const getCurrentHost = () => {
  const matchHost = window.location.host.match(/\.?([A-za-z0-9]*\.com)/);
  return matchHost ? matchHost[1] : '37games.com';
}


// 将对象装换成key=val&key=val的字符串
export const convertParamToString = function (params: {
  [x: string]: string;
}): string {
  const paramArray: string[] = [];
  for (const key in params) {
    let val = params[key];
    if ('object' === typeof val) {
      val = JSON.stringify(val);
    }
    const param = key + '=' + encodeURIComponent(val);
    paramArray.push(param);
  }
  // paramArray.push('callback');
  if (paramArray.length > 0) {
    return paramArray.join('&');
  }
  return '';
};

// 创建节流函数
export const throttle = (cb: any, time = 300) => {
  let timer: number | undefined = undefined;
  let bool = false;
  return () => {
    if (bool) {
      return;
    }
    bool = true;
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb();
      bool = false;
    }, time);
  };
};

// 创建防抖函数
export const antiShake = (cb: any, time = 300) => {
  let timer: number | undefined = undefined;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb();
    }, time);
  };
};

// 休眠
export const sleep = (time = 1000) => {
  let next: null | ((value?: unknown) => void) = null;
  const p = new Promise(resolve => {
    next = resolve;
  });
  setTimeout(() => {
    next && next();
  }, time);
  return p;
};

// 加载图片，失败尝试重载3次
export const loadImage = async (url: string): Promise<HTMLImageElement> => {
  let next: ((value: HTMLImageElement) => void) | null = null;
  const p: Promise<HTMLImageElement> = new Promise(resolve => (next = resolve));
  let count = 3;
  while (count--) {
    await new Promise(resolve => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        count = 0;
        resolve(true);
        next && next(img);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  }
  return p;
};

const STORAGE_KEY_PRE = 'website_';

// localStorage 写入
export const setLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(STORAGE_KEY_PRE + key, value);
  } catch (e) {
    console.error(
      window.navigator.userAgent + 'not support localStorage write',
      e
    );
  }
};

// localStorage 读取
export const getLocalStorage = (key: string) => {
  let value: string | null = '';
  try {
    value = localStorage.getItem(STORAGE_KEY_PRE + key);
  } catch (e) {
    console.error(
      window.navigator.userAgent + 'not support localStorage read',
      e
    );
  }
  return value ? value : '';
};


export const getBranch = () => {
  return window.location.hostname.split('.')[1];
};


// 获取当前平台
export const getPlatform = (): string => {
  let branch = getBranch();
  let platformMap: Record<string, string> = {
    gm99: 'gm99',
  };
  return platformMap[branch] || 'global';
};
