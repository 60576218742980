import { useIntersectionObserver } from '@vueuse/core';
import websiteConfig from "@/configs/websiteConfig";
import { ref } from 'vue'
import { point } from '@/utils/pointUtils';
import { HOST } from '@/api/request';
const indexRef = ref(null)
const showPayAside = ref(true);
let indexViewActive = false;
useIntersectionObserver(indexRef, ([{ isIntersecting }]) => {
    if (!indexViewActive && isIntersecting) {
        indexViewActive = true;
        point({
            eventName: 'view_page',
            eventType: '首页',
        });
    }
});
const audioState = ref(true);
const audioRef = ref(null);
const bgm = websiteConfig.index.bgm
const slogan = websiteConfig.index.slogan
const handlePayAside = state => {
    showPayAside.value = state;
    point({
        eventName: 'click_button',
        eventReason: '储值悬浮窗',
        eventType: state ? '展开' : '关闭',
    });
};
websiteConfig.payAside.default_payLink = websiteConfig.payAside.default_payLink.replace('$HOST', HOST);
websiteConfig.payAside.guidePage = websiteConfig.payAside.guidePage.replace('$HOST', HOST);
export default function () {
    return {
        bgm,
        audioState,
        audioRef,
        indexRef,
        slogan,
        showPayAside,
        handlePayAside
    }
}