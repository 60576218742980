import getJSONP, { HOST, GAME_ID } from './request';
import { getPlatform } from "@/utils";
const PLATFORM = getPlatform();
const gameInfoAction = {
    global: `//www.${HOST}/ajax/get_game_info`,
    gm99: '//m.gm99.com/ajax/get_real_game_info',
}

// 获取游戏基本信息
export const getGameInfo = () => {
    return getJSONP(gameInfoAction[PLATFORM] + '?gameID=' + GAME_ID )
}