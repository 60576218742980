export default {
  "home": "INÍCIO",
  "news": "NOVIDADES",
  "community": "COMUNIDADE",
  "character": "PERSONAGENS",
  "world": "CONFIGURAÇÃO MUNDIAL",
  "features": "RECURSOS",
  "topUp": "RECARREGAR",
  "customerService": "ATENDIMENTO AO CLIENTE",
  "download": "DOWNLOAD",
  "moreDownload": "Mais métodos de download",
  "screenshot": "CAPTURA DE TELA",
  "video": "CINEMÁTICO",
  "poster": "PÔSTER",
  "downloadGame": "DOWNLOAD",
  "scan": "Escaneie o código QR para fazer o download",
  "prev": "Anterior",
  "next": "Próximo",
  "moreNews": "INFORMAÇÃO",
  "latest": "MAIS RECENTES",
  "fwtk": "TERMOS DE SERVIÇO",
  "yszc": "POLÍTICA DE PRIVACIDADE",
  "noContent": "Sem conteúdos no momento",
  "goPay": "IR AGORA",
  "payGuide": "SABER MAIS",
  "stayTurn": "aguarde por favor",
  "back": "Voltar",

}
