<template>
  <transition name="fade">
    <div
        class="popup iframe-popup"
        v-if="popupState.iframeLink"
    >
      <div class="popup-mask fade-in" @click="hidePopup()"></div>
      <div class="popup-main">
        <a
            href="javascript:void(0);"
            class="popup-main-close"
            @click="hidePopup()"
        ></a>
        <iframe :src="popupState.iframeLink" title="iframePopup" style="width: 100%; height: 100%"></iframe>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import popupHook from '@/hooks/popupHook';
const { popupState, hidePopup } = popupHook();
</script>
