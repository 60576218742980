<template>
  <transition name="fade">
    <div
      class="popup download-popup"
      v-if="popupState.downloadWay && mediaClass.portOrLand === 'land'"
    >
      <div class="popup-mask fade-in" @click="hidePopup()"></div>
      <div class="popup-main limit-px" @click="clickBtnUpload">
        <a
          href="javascript:void(0);"
          class="popup-main-close"
          @click="hidePopup()"
        ></a>
        <a
          :href="gameInfo.appstore"
          target="_blank"
          class="apple download-btn limit-px ib common-download-apple"
          data-reason="moreWay"
          data-type="appstore"
        ></a>
        <a
          :href="gameInfo.googlePlay"
          target="_blank"
          class="google download-btn limit-px ib common-download-google"
          data-reason="moreWay"
          data-type="google"
        ></a>
        <a
          v-if="gameInfo.apk"
          :href="gameInfo.apk"
          target="_blank"
          class="apk download-btn limit-px ib common-download-apk"
          data-reason="moreWay"
          data-type="apk"
        ></a>
        <a
          v-if="gameInfo.ldPlayer"
          :href="gameInfo.ldPlayer"
          target="_blank"
          class="ldPlayer download-btn limit-px ib"
          data-reason="moreWay"
          data-type="ldPlayer"
        ></a>
        <a
          :href="gameInfo.yeshen"
          target="_blank"
          class="pc download-btn limit-px ib"
          data-reason="moreWay"
          data-type="pc"
        ></a>
        <a
          v-if="gameInfo.blueStack"
          :href="gameInfo.blueStack"
          target="_blank"
          class="bluestacks download-btn limit-px ib"
          data-reason="moreWay"
          data-type="bluestacks"
        ></a>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import popupHook from '@/hooks/popupHook';
const { popupState, hidePopup } = popupHook();
import mediaClass from '@/hooks/queryMediaClass.ts';
import { clickBtnUpload } from '@/utils/pointUtils';
import { gameInfo } from '@/hooks/gameInfoHook';
</script>
