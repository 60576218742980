import { useMediaQuery } from '@vueuse/core';
import { reactive, watchEffect } from 'vue';
const screenWidthList: number[] = [580, 940, 1366, 1920];
const screenWidthClassList: string[] = ['mobile', 'small', 'middle', 'large'];
const screenTypeQueryString = (pixel: string | number) => {
  return `(max-width: ${pixel}px)`;
};
let mediaClass = reactive({
  screenType: null,
  portOrLand: false,
  tinyLand: false,
});
watchEffect(() => {
  mediaClass.screenType = null;
  screenWidthList.forEach((width, index) => {
    if (
      useMediaQuery(screenTypeQueryString(width)).value &&
      !mediaClass.screenType
    ) {
      mediaClass.screenType = screenWidthClassList[index];
    }
  });
  mediaClass.portOrLand = useMediaQuery('(orientation:portrait)').value
    ? 'po'
    : 'land';
  mediaClass.tinyLand = useMediaQuery('(max-height: 685px)').value;
});
export default mediaClass;