<template>
  <div class="social" v-if="thirdParty.length === 1">
    <a
      :href="thirdParty[0].link"
      target="_blank"
      class="ib limit-px"
      :class="thirdParty[0].type"
    ></a>
  </div>
</template>

<script lang="ts" setup>
import websiteConfig from "@/configs/websiteConfig";
const thirdParty = websiteConfig.menu.thirdParty;
</script>
